import * as React from 'react';
import Button from 'components/atoms/Button';
import useModal from 'hooks/useModal';
import Modal from 'components/molecules/Modal';
import Input from 'components/molecules/Input';
import useForm from 'hooks/useForm';
import { actualizarEstadosGastos } from 'network/Api/Gasto';
import { now } from 'utils/DateUtils';
const { useCallback } = React;

const f = 'YYYY-MM-DD';
const stateD = { feInicio: now().format('YYYY-MM-01'), feFin: now().format(f) };

function ActualizarEstados({ emisor }) {
  const [props, open, close] = useModal();
  const [messageProps, openMessage] = useModal();
  const { state, handleChange } = useForm(stateD);

  const onSubmit = useCallback(
    async function (event) {
      event.preventDefault();
      const { feInicio, feFin, dsNit: dsNitEmisor } = state;
      await actualizarEstadosGastos({ emisor, feInicio, feFin, dsNitEmisor });
      openMessage({ content: 'Actualizando eventos podras revisa los cambios mas tarde' });
      close();
    },
    [state, emisor],
  );

  const openGastos = useCallback(function () {
    open({});
  }, []);

  return (
    <>
      <Button onClick={openGastos}>Procesar Masivamente</Button>
      <Modal {...messageProps} />
      <Modal
        {...props}
        fullWidth
        maxWidth='md'
        title=''
        content={
          <form onSubmit={onSubmit}>
            <div className='form-row'>
              <Input
                className='form-group col-4'
                label='Fecha Inicio'
                type='date'
                name='feInicio'
                onChange={handleChange}
                value={state.feInicio}
                max={state.feFin}
                require
              />
              <Input
                className='form-group col-4'
                label='Fecha Fin'
                type='date'
                name='feFin'
                onChange={handleChange}
                value={state.feFin}
                min={state.feInicio}
                require
              />
              <Input
                className='form-group col-4'
                label='NIT'
                name='dsNit'
                onChange={handleChange}
                value={state.dsNit}
              />
            </div>
            <div className='form-row'>
              <Button type='submit'>Procesar</Button>
            </div>
          </form>
        }
      />
    </>
  );
}

export default ActualizarEstados;
