import * as React from 'react';
import { Grid } from '@material-ui/core';
import Button from 'components/atoms/Button';
import Input from 'components/molecules/Input';
import ButtonDescargaExcel from './ButtonDescargaExcel';
const { useMemo } = React;

const FiltroConsultas = ({ handleChange, consultarGastos, state }) => {
  const { feInicio, feFin, prefijo, numero, nombreEmisor, cufe, gastos, dsIdEmisor } = useMemo(
    () => state,
    [state],
  );
  return (
    <Grid container spacing={8}>
      <Grid item xs={6}>
        <Input
          label='Fecha inicio'
          type='date'
          name='feInicio'
          value={feInicio}
          onChange={handleChange}
          max={feFin}
        />
      </Grid>
      <Grid item xs={6}>
        <Input
          label='Fecha fin'
          type='date'
          name='feFin'
          value={feFin}
          onChange={handleChange}
          min={feInicio}
        />
      </Grid>
      <Grid item xs={4}>
        <Input label='Prefijo' name='prefijo' value={prefijo} onChange={handleChange} />
      </Grid>
      <Grid item xs={4}>
        <Input label='Num. documento' name='numero' value={numero} onChange={handleChange} />
      </Grid>
      <Grid item xs={4}>
        <Input label='CUFE' name='cufe' value={cufe} onChange={handleChange} />
      </Grid>
      <Grid item xs={6}>
        <Input
          label='Nombre emisor'
          name='nombreEmisor'
          value={nombreEmisor}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={6}>
        <Input
          label='NIT del emisor'
          name='dsIdEmisor'
          value={dsIdEmisor}
          onChange={handleChange}
        />
      </Grid>
      <Grid
        style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-around' }}
        xs={12}
        item
      >
        <Button onClick={consultarGastos}>Consultar</Button>
        <ButtonDescargaExcel gastos={gastos} />
      </Grid>
    </Grid>
  );
};

export default FiltroConsultas;
